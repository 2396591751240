import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { toast } from "sonner";
import { CheckCircleIcon, XIcon } from "lucide-react";
import { Product, Sku, createApiClient } from "./api";
import { LocaleLink } from "~/components/atoms/locale-link";

export type SkuProduct = Sku & {
  product: Product;
};

export type CartItem = {
  count: number;
  sku: SkuProduct;
};

export type CartState = {
  items: CartItem[];
  itemsCount: () => number;
  totalPrice: () => number;
};

export type CartActions = {
  addToCart: (sku: SkuProduct, count?: number) => void;
  setItemCount: (skuId: SkuProduct["id"], count: number) => void;
  removeItem: (skuId: SkuProduct["id"]) => void;
  emptyCart: () => void;
};

const useCart = create(
  persist<CartState & CartActions>(
    (set, get) => ({
      // STATE
      items: [],
      // COMPUTED STATE
      itemsCount: () =>
        get().items.reduce((count, item) => count + item.count, 0),
      totalPrice: () =>
        get().items.reduce(
          (totalPrice, item) => totalPrice + item.count * item.sku.price,
          0,
        ),
      // ACTIONS
      addToCart: (sku, count = 1) => {
        const { items } = get();

        const exisitngItem = items.find((item) => item.sku.id === sku.id);

        toast.custom((t) => (
          <div className="relative flex items-center w-full gap-4 px-4 py-2 pr-10 text-green-800 bg-green-100 border border-green-200 shadow-lg rounded-xl">
            <LocaleLink
              unstable_viewTransition
              className="flex items-center gap-4"
              route="cart"
              onClick={() => toast.dismiss(t)}
            >
              <CheckCircleIcon size={32} />
              <div className="flex flex-col">
                <div className="text-xs font-medium">
                  Produkt bol pridaný do košíka
                </div>
                <div>{sku.product.name}</div>
              </div>
            </LocaleLink>
            <button
              className="absolute top-2 right-2"
              onClick={() => toast.dismiss(t)}
            >
              <XIcon />
            </button>
          </div>
        ));

        if (!exisitngItem) {
          set({ items: [...items, { sku, count }] });
          return;
        }

        set({
          items: items.map((item) => {
            if (item.sku.id === sku.id) {
              return {
                sku,
                count: item.count + count,
              };
            }
            return item;
          }),
        });
      },
      removeItem: (productId) => {
        const { items } = get();

        set({
          items: items.filter((item) => item.sku.id !== productId),
        });
      },
      setItemCount: (productId, count) => {
        const { items } = get();

        // Remove
        if (count <= 0) {
          set({
            items: items.filter((item) => item.sku.id !== productId),
          });
          return;
        }
        set({
          items: items.map((item) => {
            if (item.sku.id === productId) {
              return {
                sku: item.sku,
                count: count,
              };
            }
            return item;
          }),
        });
      },
      emptyCart: () => {
        set({
          items: [],
        });
      },
    }),
    {
      name: "cart-storage",
      storage: createJSONStorage(() => sessionStorage),
      version: 1,
      migrate: () => {
        // Remove all
        return { items: [] as CartItem[] } as CartState & CartActions;
      },
    },
  ),
);

const validateCart = () => {
  const api = createApiClient(true);

  Promise.allSettled(
    useCart.getState().items.map(async (item): Promise<CartItem | null> => {
      try {
        const product = await api.getProductBySkuId(item.sku.id);

        const sku = product.skus.find((sku) => sku.id == item.sku.id);

        if (!sku) return null;

        if (sku.quantity === 0) return null;

        return {
          count: Math.min(sku.quantity, item.count),
          sku: {
            ...sku,
            product,
          },
        };
      } catch {
        return null;
      }
    }),
  ).then((items) => {
    useCart.setState({
      items: items
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .map((item) => (item as any).value)
        .filter((item) => item),
    });
  });
};

export const cart = { useCart, validateCart };
